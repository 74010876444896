import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { PRIMARY } from "../theme/colors";
import { FONTS } from "../utils/fontConstant";
import { routes } from "../routes/routesPath";
import { analyticsEvent } from "../utils/analyticsHelper";

type Props = {
  item: {
    title: string;
    path: string;
    icons: JSX.Element;
    focus: boolean;
    subMenu?: any;
    newTab: boolean;
    eventName: string;
  };
  navigate: any;
  currentPath: string;
  isSubMenu?: boolean;
  handleDrawerClose: () => void;
};
const DrawerListItem = ({
  item,
  navigate,
  currentPath,
  isSubMenu = true,
  handleDrawerClose,
}: Props) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const handleItemClick = () => {
    handleDrawerClose();
    analyticsEvent({ eventName: item.eventName });
    if (item.path) {
      if (item.newTab) {
        if (item.path === routes.FORUM) {
          
          window.open(process.env.REACT_APP_DISCOURSE_URL);
        } else {
          window.open(item.path);
        }
      } else {
        navigate(item.path);
      }
    }
  };
  return (
    <>
      <ListItem
        sx={{
          backgroundColor: item.focus ? PRIMARY + 50 : "",
          my: 1,
        }}
        disablePadding
      >
        <ListItemButton
          sx={{ pl: !isSubMenu ? 5 : 2 }}
          onClick={handleItemClick}
        >
          <ListItemIcon sx={{ minWidth: 36 }}>{item.icons}</ListItemIcon>
          <ListItemText>
            <Typography
              fontFamily={
                item.focus
                  ? FONTS.SOURCESANSPRO_BOLD
                  : FONTS.SOURCESANSPRO_REGULAR
              }
              color={item.focus ? "primary" : "white"}
            >
              {item.title}
            </Typography>
          </ListItemText>
        </ListItemButton>
        {isSubMenu && item.subMenu?.length !== 0 && (
          <IconButton onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </ListItem>
      {isSubMenu && item.subMenu?.length !== 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subMenu.map((subItem: any) => (
              <DrawerListItem
                handleDrawerClose={handleDrawerClose}
                key={subItem.title}
                isSubMenu={false}
                navigate={navigate}
                item={subItem}
                currentPath={currentPath}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default DrawerListItem;
