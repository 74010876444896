import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_USER_MEDICAL,
  GET_USER_MEDICAL_PARAMETERS_ID,
  POST_USER_MEDICAL,
} from "../../../redux/actions";
import {
  getUserMedicalParametersIdApi,
  userMedicalParametersApi,
  userMedicalParametersV2Api,
} from "../../../routes/apiRoutes";

export const postUserMedicalParametersMiddleWare = createAsyncThunk(
  POST_USER_MEDICAL,
  async ({ payload }: { payload: any }) => {
    try {
      const { data } = await axios.post(userMedicalParametersV2Api, payload);
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getUserMedicalParametersMiddleWare = createAsyncThunk(
  GET_USER_MEDICAL,
  async ({ userId }: { userId?: string }) => {
    try {
      const { data } = await axios.get(
        userId
          ? getUserMedicalParametersIdApi(userId)
          : userMedicalParametersApi
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getUserMedicalParametersIdMiddleWare = createAsyncThunk(
  GET_USER_MEDICAL_PARAMETERS_ID,
  async ({ userId }: { userId: string }) => {
    try {
      const { data } = await axios.get(getUserMedicalParametersIdApi(userId));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
