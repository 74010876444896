import { Divider, List, Toolbar } from "@mui/material";
import DrawerListItem from "./DrawerListItem";
import { drawerWidth } from "./ResponsiveDrawer";

type Props = {
  pages: {
    title: string;
    path: string;
    icons: JSX.Element;
    focus: boolean;
    subMenu?: any;
    newTab: boolean;
    show?: boolean;
    eventName: string;
  }[];
  navigate: any;
  currentPath: string;
  page_1: {
    title: string;
    path: string;
    icons: JSX.Element;
    focus: boolean;
    newTab: boolean;
    show?: boolean;
    eventName: string;
  }[];
  handleDrawerClose: () => void;
};
const DrawerList = ({
  pages,
  navigate,
  currentPath,
  page_1,
  handleDrawerClose,
}: Props) => {
  return (
    <div style={{ width: drawerWidth }}>
      <Toolbar></Toolbar>
      <Divider />
      <List>
        {pages.map((item) =>
          item.show ? (
            <DrawerListItem
              handleDrawerClose={handleDrawerClose}
              key={item.title}
              navigate={navigate}
              item={item}
              currentPath={currentPath}
            />
          ) : (
            <div key={item.title} />
          )
        )}
        <Divider />
        {page_1.map((item) =>
          item.show ? (
            <DrawerListItem
              handleDrawerClose={handleDrawerClose}
              key={item.title}
              navigate={navigate}
              item={item}
              currentPath={currentPath}
            />
          ) : (
            <div key={item.title} />
          )
        )}
      </List>
    </div>
  );
};

export default DrawerList;
