import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  CREATE_TODO_ITEMS,
  DELETE_TODO_ITEMS,
  GET_BY_ID_TODO_ITEMS,
  GET_TODO_ITEMS,
  GET_TODO_LISTS,
  PENDING_TODO_ITEMS,
  UPDATE_TODO_ITEMS,
} from "../../../redux/actions";
import {
  getTodoItemsApi,
  getTodoListsApi,
  testosteronologistTodosApi,
  testosteronologistTodosCompleteApi,
  testosteronologistTodosDeleteApi,
  updateStatusCompleteApi,
  updateStatusPendingApi,
  updateTodoApi,
} from "../../../routes/apiRoutes";
import { isEmpty } from "../../../utils/validators";

export const getTodoItemsMiddleWare = createAsyncThunk(
  GET_TODO_ITEMS,
  async (_a) => {
    try {
      const { data } = await axios.get(getTodoItemsApi);
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getTodoListMiddleWare = createAsyncThunk(
  GET_TODO_LISTS,
  async ({
    status,
    page,
    pageSize,
    userId,
  }: {
    status?: "pending" | "completed";
    page: number;
    pageSize: number;
    userId?: string;
  }) => {
    try {
      const { data } = await axios.get(
        !isEmpty(userId) ? testosteronologistTodosApi(userId) : getTodoListsApi,
        {
          params: {
            status,
            page,
            pageSize,
          },
        }
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const createTodoMiddleWare = createAsyncThunk(
  CREATE_TODO_ITEMS,
  async ({
    item,
    scheduledAt,
    userId,
  }: {
    item: string;
    scheduledAt: any;
    userId: string;
  }) => {
    try {
      const { data } = await axios.post(
        isEmpty(userId) ? getTodoListsApi : testosteronologistTodosApi(userId),
        { item, scheduledAt }
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const updateTodoMiddleWare = createAsyncThunk(
  UPDATE_TODO_ITEMS,
  async ({
    item,
    scheduledAt,
    id,
  }: {
    item: string;
    scheduledAt: string;
    id: string;
  }) => {
    try {
      const { data } = await axios.put(updateTodoApi(id), {
        item,
        scheduledAt,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const completeTodoMiddleWare = createAsyncThunk(
  UPDATE_TODO_ITEMS,
  async ({ id, userId }: { id: string; userId: string }) => {
    try {
      const { data } = await axios.put(
        isEmpty(userId)
          ? updateStatusCompleteApi(id)
          : testosteronologistTodosCompleteApi(userId, id)
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const pendingTodoMiddleWare = createAsyncThunk(
  PENDING_TODO_ITEMS,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.put(updateStatusPendingApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getTodoByIdMiddleWare = createAsyncThunk(
  GET_BY_ID_TODO_ITEMS,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.get(updateTodoApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const deleteTodoMiddleWare = createAsyncThunk(
  DELETE_TODO_ITEMS,
  async ({ id, userId }: { id: string; userId?: string }) => {
    try {
      const { data } = await axios.delete(
        isEmpty(userId)
          ? updateTodoApi(id)
          : testosteronologistTodosDeleteApi(userId, id)
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
