import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getZoomApi,
  getZoomListApi,
  zoomCreateSignatureApi,
  zoomNewSessionApi,
  zoomNewSessionTwoApi,
  zoomDeleteSessionTwoApi,
} from "../../../routes/apiRoutes";
import {
  GET_ZOOM_ID,
  ZOOM_CREATE_SIGNATURE,
  ZOOM_DELETE_SESSION_2,
  ZOOM_LIST,
  ZOOM_NEW_SESSION,
  ZOOM_NEW_SESSION_2,
} from "../../../redux/actions";

export const getZoomIDMiddleWare = createAsyncThunk(
  GET_ZOOM_ID,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.get(getZoomApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getZoomListMiddleWare = createAsyncThunk(
  ZOOM_LIST,
  async ({
    page,
    pagesize,
    oldsession,
  }: {
    page: number;
    pagesize: number;
    oldsession: boolean;
  }) => {
    try {
      const { data } = await axios.get(getZoomListApi, {
        params: { page, pagesize, oldsession },
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const zoomNewSessionMiddleWare = createAsyncThunk(
  ZOOM_NEW_SESSION,
  async ({
    sessionName,
    startTime,
    endTime,
    description,
    isGenderSpecific,
    participantsGender,
  }: {
    sessionName: string;
    startTime: string;
    endTime: string;
    description: string;
    isGenderSpecific: boolean;
    participantsGender: string;
  }) => {
    try {
      const { data } = await axios.post(zoomNewSessionApi, {
        sessionName,
        startTime,
        endTime,
        description,
        isGenderSpecific,
        participantsGender,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const zoomNewSessionTwoMiddleWare = createAsyncThunk(
  ZOOM_NEW_SESSION_2,
  async ({
    sessionName,
    startTime,
    endTime,
    description,
    isGenderSpecific,
    participantsGender,
    meetingURL,
  }: {
    sessionName: string;
    startTime: string;
    endTime: string;
    description: string;
    isGenderSpecific: boolean;
    participantsGender: string;
    meetingURL: string;
  }) => {
    try {
      const { data } = await axios.post(zoomNewSessionTwoApi, {
        sessionName,
        startTime,
        endTime,
        description,
        isGenderSpecific,
        participantsGender,
        meetingURL,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const zoomDeleteSessionTwoMiddleWare = createAsyncThunk(
  ZOOM_DELETE_SESSION_2,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.delete(zoomDeleteSessionTwoApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const zoomCreateSignatureMiddleWare = createAsyncThunk(
  ZOOM_CREATE_SIGNATURE,
  async ({ id }: { id: string }) => {
    try {
      const { data } = await axios.post(zoomCreateSignatureApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);
