export const PROFILE_PLACEHOLDER =
  "https://anabolic-doc-public.s3.amazonaws.com/app-images/user.png";

export const USER_PAYMENT_MODE = {
  APPLE_IAP: "apple-iap",
  GOOGLE_IAP: "google-iap",
  STRIPE: "stripe",
};

export const ABCD_VIDEO_URL =
  "https://anabolic-doc-public.s3.amazonaws.com/ABCDs-Portrait+v1.mp4";
export const BECOME_A_PROVIDER_VIDEO =
  "https://anabolic-doc-qa-public.s3.us-east-1.amazonaws.com/Become+a+Provider-1920.mp4";
export const FIND_A_PROVIDER_VIDEO =
  "https://anabolic-doc-public.s3.amazonaws.com/2-Find+a+Provider-Short+v+5.mp4";

export const ROLE = {
  ADMIN: "admin",
  USER: "user",
  TESTOSTERONOLOGIST: "testosteronologist",
};

export const HEALTH_AND_PHYSICAL_STRING = "healthAndPhysical";
