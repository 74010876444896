export const useLanguage = {
  ABCDS_TM: `ABCD’S™`,
  REPORT: "Report",
  FORUMS: "Forums",
  STRONG_HEALTHY_MEN_AND_WOMEN: `Strong & Healthy, Men and Women`,
  VIEW_YOUR_RESULTS_NOW: "View your results now",
  UPGRADE_TO_PRO: "Upgrade to Pro",
  UPGRADE_TO_PRO_PLAN: "Upgrade to Pro plan",
  TO_UNLOCK_THE_FULL_POTENTIAL:
    "To unlock the full potential of Testeronology, consider upgrading your access level.",
  H_P_KNOWLEDGE_BASE: "H & P Knowledge Base",
  MY_HEALTH_TO_DO_LIST: "My Health To Do List",
  TO_DO_LIST: "To Do List",
  PICK_FROM_THE_LIST: "Pick From The List",
  PERSONAL_ASSESSMENT_PREVENTIVE_HEALTH_TOOL:
    "Personal Assessment Preventive Health Tool™",
  FEATURING__AND_TESTOSTERONOLOGY_CONTENT:
    "Featuring Dr. O and Testosteronology® Content",
  ORDER_BLOOD_LABS: "Order Blood Labs",
  TESTOSTERONOLOGY: "Testosteronology®",
  TESTOSTERONOLOGY_PRIVATE_FORUM: "Testosteronology® Private Forums",
  ASK_DR_O_CONNOR_GPT: "Ask Dr. O’Connor GPT",
  IN_APP_VIDEOS: "Private in App Videos on TRT/AAS Health Content",
  ASK_THE_DOC_LIVE_MEETINGS:
    "Ask the Doc & Testosteronologist™ Live Q&A Meetings",
  FIND_A_TESTOSTERONOLOGIST: "Find a Testosteronologist™",
  FAQS: `FAQ's`,
  MAILBAG: "Mailbag",
  RECENT: "Recent",
  PROFILE: "Profile",
  CUSTOMER_SUPPORT: "Customer Support",
  TERMS_CONDITIONS: "Terms & Conditions",
  PRIVACY_POLICY: "Privacy Policy",
  DR_O_CONNOR_VIA_GPT: `Dr. O’Connor via GPT`,
  EDIT_PROFILE: "Edit Profile",
  ADD_DETAILS: "Add details",
  USER_SETTINGS: "User Settings",
  UPDATE_FORUMS_PASSWORD: "Update Forums Password",
  COPYRIGHT_TESTOSTERONOLOGY: "Copyright 2025 Testosteronology®",
  BACK_TO_HOME_PAGE: "Back to home page",
  EMAIL_FIELD_REQUIRED: "Email field required",
  INVALID_EMAIL_ENTERED: "Invalid email entered",
  PASSWORD_FIELD_REQUIRED: "Password field required",
  LAST_NAME_FIELD_REQUIRED: "Last name field required",
  VIEW_ALL: "View all",
  VIDEOS: "Videos",
  //  NEW HOME DESGIN
  PLAY_NOW: "Play now",
  LATEST_VIDEOS: "Latest Videos",
  GET_ANSWERS: "Get Answers",
  ASSESS_MAINTAIN_YOUR_HEALTH: "Assess & Maintain Your Health",
  ASK_DOC_LIVE_QA: "Ask The Doc Live Q&A",
  FORUM: "Forum",
  ABCDS_HEALTH_DIAGNOSTIC: "ABCD’S™ Health Diagnostic",
  ORDER_BLOOD_TESTS_RESULTS: "Order Blood Tests & Store Results",
  EXECUTIVE_HISTORY_PHYSICAL: "Executive History & Physical",
  ASK_DR_O_GPT: "Ask Dr. O GPT",
  ////////-----------------///////////
  NEW_MAILBAG: "New Mailbag",
  ABCD_RESULTS_ARE_SHOWN_BELOW: "ABCD’S™ Results are shown below",
  PLEASE_ENTER_YOUR_ABCD_DETAIL: "ABCD’S detail",
  ABCDS_VIDEO_EXPLANATION: `ABCD'S™ Video Explanation`,
  CERTIFIED_LICENSED_TESTOSTERONOLOGIST:
    "Certified/Licensed Testosteronologist™ Course",
  A_PROGRAM_METICULOUSLY_CRAFTED:
    "A program meticulously crafted for Nurse Practitioners (NPs), Physician Assistants (PAs), and medical doctors seeking to augment their proficiency in sub-specialization of Testosteronology®.",
  TESTOSTERONOLOGIST_PROGRAM_INCLUDES:
    "Testosteronologists™ are included in our Find a Provider page to receive new patients and get access to weekly Zoom meetings to ask clinical questions of Dr. O'Connor and other Testosteronologists™. The program is $99/monthly or $995/yearly.",
  // ENROLL_NOW: "Enroll for Testosteronologist",
  ENROLL_TODAY: "Enroll Today",
  FEATURES_AND_BENEFITS: "Features and Benefits",
  WHAT_IS_A_TESTOSTERONOLOGIST: "What is a Testosteronologist?",
  COURSE_OBJECTIVES: "Course Objectives",
  KEY_OBJECTIVES: "Key Objectives:",
  COURSE_MODULES: "Course Modules",
  // MEMBERS_HAVE_WEEKLY_ACCESS:
  //   "Members have weekly access to Dr. O'Connor and other Testosteronologist’s through live video conferences and  private, pre-recorded content where you will stay apprised of relevant anecdotal case studies from real clinical scenarios.",
  MEDICAL_QUESTIONS_ANSWERED_HRS_OF_VIA_GPT:
    "Medical questions answered by our GPT trained by thousands of hours of Dr. O's videos",
  A1C_HEMOGLOBIN_A1C: "A1C - Hemoglobin A1C",
  FIND_A_PROVIDER: "Find A Provider",
  BECOME_A_PROVIDER: "Become A Provider",
  ENROLL: "Enroll",
  FIND_A_PROVIDER_DETAILS: "Find A Provider Details",
  ABOUT: "About",
  SIGN_IN: "Sign In",
  SIGN_UP: "Sign Up",
  FORGET_PASSWORD: "Forget Password",
  OTP_VERIFICATION: "OTP Verification",
  HOME: "Home",
  A1C_THE_A1C_TEST:
    "A1C: The A1C test is a common blood test used to diagnose type 1 and type 2 diabetes.",
  WHAT_YOUR_A1C_HEMOGLOBIN_A1C: "What’s your A1C - Hemoglobin A1C?",
  CONNECTION_TO_YOUR_TESTO: "Connection To Your Testo",
  FIND_YOUR_TESTOSTERONOLOGIST:
    "Find your Testosteronologist listed below and connect them to your account.",
  CONNECT_YOUR_TESTOSTERONOLOGIST: "Connect your Testosteronologist",
  SEARCH_USING_NAME: "Search using Name",
  CONNECT: "Connect",
  INVITE: "Invite",
  CONNECT_YOUR_TESTO_AGREEMENT: "Connect Your Testo Agreement",
  CONTINUE: "Continue",
  REQUESTED: "Requested",
  LABS: "Labs",
};
