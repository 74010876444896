import React from "react";
import { CenterView } from "../packages";
import ResponsiveDrawer from "./ResponsiveDrawer";
import { Outlet } from "react-router-dom";

const Layout = React.memo(({ isNav }: { isNav?: boolean }) => {
  return (
    <>
      {isNav ? (
        <CenterView>
          <ResponsiveDrawer>
            <Outlet />
          </ResponsiveDrawer>
        </CenterView>
      ) : (
        <CenterView>
          <Outlet />
        </CenterView>
      )}
    </>
  );
});

export default Layout;
