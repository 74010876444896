import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ATTACH_LAB,
  DELETE_REPORT,
  GET_FILE,
  MY_LAB_GET_REPORT,
  UPLOAD_LAB,
} from "../../../redux/actions";
import {
  getLabReportApi,
  getFileApi,
  attachLabFileApi,
  testosteronologistLabReportsApi,
  testosteronologistLabReportsFilesApi,
  deleteReportByIdApi,
} from "../../../routes/apiRoutes";
import { isEmpty } from "../../../utils/validators";

export const getLabReportMiddleWare = createAsyncThunk(
  MY_LAB_GET_REPORT,
  async ({
    page,
    pageSize,
    search,
    userId,
  }: {
    page: number;
    pageSize: number;
    search?: any;
    userId: string;
  }) => {
    try {
      const { data } = await axios.get(
        isEmpty(userId)
          ? getLabReportApi
          : testosteronologistLabReportsApi(userId),
        {
          params: {
            page,
            pageSize,
            search,
          },
        }
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const uploadLabMiddleWare = createAsyncThunk(
  UPLOAD_LAB,
  async ({
    reportTakenOn,
    reportTitle,
    reportDescription,
  }: {
    reportTakenOn: string;
    reportTitle: string;
    reportDescription: string;
  }) => {
    try {
      const { data } = await axios.post(getLabReportApi, {
        reportTakenOn,
        reportTitle,
        reportDescription,
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const attachLabMiddleWare = createAsyncThunk(
  ATTACH_LAB,
  async ({ id, formData }: { id: string; formData: any }) => {
    try {
      const { data } = await axios.post(attachLabFileApi(id), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const getFileMiddleWare = createAsyncThunk(
  GET_FILE,
  async ({ id, userId }: { id: any; userId?: string }) => {
    try {
      const { data } = await axios.get(
        isEmpty(userId)
          ? getFileApi(id)
          : testosteronologistLabReportsFilesApi(userId, id)
      );
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return typedError;
    }
  }
);

export const deleteReportByIdMiddleWare = createAsyncThunk(
  DELETE_REPORT,
  async (
    {
      id,
    }: {
      id: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.delete(deleteReportByIdApi(id));
      return data;
    } catch (error: any) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  }
);
